import 'mapbox-gl/dist/mapbox-gl.css';
import {
	Button,
	Carousel,
	CarouselItem,
	Dialog,
	DialogContent,
	DialogTrigger,
	Heading,
	HorizontalRule,
	Link,
	Picture,
	TextLink,
} from '@troon/ui';
import { For, Show } from 'solid-js';
import { IconInfo } from '@troon/icons/info';
import { IconPhone } from '@troon/icons/phone';
import { gql } from '../../graphql';
import { createFragment } from '../../graphql/create-fragment';
import { courseTypeString } from '../facility/type-strings';
import type { FragmentType } from '../../graphql';

type Props = {
	facility: FragmentType<typeof FacilityCardFragment>;
	bookingUrl?: string;
	phone?: string;
	showInfoLink?: boolean;
};

export function FacilityCard(props: Props) {
	const facility = createFragment(FacilityCardFragment, props, 'facility');

	return (
		<article class="group flex flex-col gap-4">
			<div class="flex flex-col gap-px">
				<Heading as="h1" size="h4">
					<Link class="group-hover:underline" href={`/course/${facility.slug}`}>
						{facility.name}
					</Link>
				</Heading>
				<p class="order-first text-sm uppercase tracking-widest text-neutral-800">{courseTypeString[facility.type]}</p>
				<p class="text-sm text-neutral-800">{Object.values(facility.metadata?.address ?? []).join(', ')}</p>
			</div>
			<div class="relative order-first overflow-hidden rounded">
				<Carousel description={`Photos of ${facility.name}`}>
					<CarouselItem class="mx-2 w-full overflow-hidden rounded">
						<Picture
							src={facility.metadata?.hero?.url}
							width={720}
							height={480}
							sizes="(min-width: 1024px) 48rem, 95vw"
							// eslint-disable-next-line tailwindcss/no-arbitrary-value
							class="aspect-[16/10] w-full overflow-hidden rounded object-cover group-hover:scale-105 motion-safe:transition-transform lg:aspect-[16/7]"
							loading="lazy"
						/>
					</CarouselItem>
					<For each={facility.metadata?.gallery}>
						{(galleryItem) => (
							<CarouselItem class="mx-2 w-full overflow-hidden rounded">
								<Picture
									src={galleryItem.url}
									width={720}
									height={480}
									sizes="(min-width: 1024px) 48rem, 95vw"
									// eslint-disable-next-line tailwindcss/no-arbitrary-value
									class="aspect-[16/10] w-full overflow-hidden rounded object-cover group-hover:scale-105 motion-safe:transition-transform lg:aspect-[16/7]"
									loading="lazy"
								/>
							</CarouselItem>
						)}
					</For>
				</Carousel>

				<div class="absolute bottom-4 left-4 box-content aspect-square size-20 shrink-0 overflow-hidden rounded-md border border-neutral bg-white p-1">
					<Picture
						src={facility.metadata?.logo?.url}
						alt=""
						width={56}
						height={56}
						sizes="3.5rem"
						mode="contain"
						class="size-20 object-cover"
						loading="lazy"
					/>
				</div>
			</div>
			<HorizontalRule />
			<p class="line-clamp-3">{facility.metadata?.description}</p>
			<div class="flex justify-start gap-4">
				<Show when={facility.isBookable || props.bookingUrl}>
					<Button as={Link} href={props.bookingUrl ?? `/course/${facility.slug}/reserve-tee-time`} class="grow-0">
						Book a Tee Time
					</Button>
				</Show>
				<Show when={props.phone}>
					<Dialog key="course-phone-number">
						<DialogTrigger appearance="tertiary" class="grow-0">
							<IconPhone />
							<span class="sr-only">Call {facility.name}</span>
						</DialogTrigger>
						<DialogContent header={`Call ${facility.name}`} headerLevel="h2">
							<div class="relative flex justify-between rounded border border-neutral px-4 py-6">
								<div class="flex flex-col">
									<strong>Phone</strong>
									<TextLink href={`tel:${props.phone}`} class="after:absolute after:inset-0">
										{props.phone}
									</TextLink>
								</div>
								<span class="flex size-10 items-center justify-center rounded bg-brand-100 p-1 text-xl text-brand">
									<IconPhone />
								</span>
							</div>
						</DialogContent>
					</Dialog>
				</Show>
				<Show when={props.showInfoLink !== false}>
					<Button appearance="tertiary" as={Link} href={`/course/${facility.slug}`} class="grow-0">
						<IconInfo />
						<span class="sr-only">More information about {facility.name}</span>
					</Button>
				</Show>
			</div>
		</article>
	);
}

export const FacilityCardFragment = gql(`fragment FacilityCardFragment on Facility {
	slug
	name
	type
	isBookable
	metadata {
		description
		address { city, state }
		hero { url }
		logo { url }
		gallery { url }
	}
}`);
