import './map.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapbox from 'mapbox-gl';
import { Button, Heading, Picture } from '@troon/ui';
import { createEffect, createMemo, createRenderEffect, createSignal, Show } from 'solid-js';
import { IconMagnifyingGlassMinus } from '@troon/icons/magnifying-glass-minus';
import { IconMagnifyingGlassPlus } from '@troon/icons/magnifying-glass-plus';
import { IconMenuAlt03 } from '@troon/icons/menu-alt-03';
import { IconShrink } from '@troon/icons/shrink';
import { IconExpand } from '@troon/icons/expand';
import { IconMap } from '@troon/icons/map';
import { render } from 'solid-js/web';
import { useTrackEvent } from '@troon/analytics';
import { captureException } from '@sentry/solidstart';
import { getConfigValue } from '../../modules/config';
import { FacilityType, gql } from '../../graphql';
import { courseTypeString } from '../facility/type-strings';
import type { Marker, Map } from 'mapbox-gl';
import type { DocumentType, FacilityMapInfoFragment, FragmentType } from '../../graphql';

type Handler = {
	setFocus: (facility?: FragmentType<typeof MapFragment> | FacilityMapInfoFragment, zoom?: boolean) => void;
};

type Props = {
	ref: (handler: Handler) => void;
	facilities: Array<FragmentType<typeof MapFragment>> | undefined;
	latitude?: number | null;
	longitude?: number | null;
	onExpand?: (expanded: boolean) => void;
	onSelectFacility: (facility: DocumentType<typeof MapFragment>) => void;
	onSearchBounds?: (center: { lng: number; lat: number }, radius: number) => void;
};

type LatLngBounds = [[number, number], [number, number]];

function MapContainer(props: Props) {
	const [ref, setRef] = createSignal<HTMLDivElement>();
	const [map, setMap] = createSignal<Map>();
	mapbox.accessToken = getConfigValue('MAPBOX_ACCESS_TOKEN');
	const markers: Record<string, Marker> = {};
	const [showSearchButton, setShowSearchButton] = createSignal(false);
	const [markerRef, setMarkerRef] = createSignal<HTMLElement>();
	const [mapExpanded, setMapExpanded] = createSignal(false);
	const trackEvent = useTrackEvent();
	const [mapError, setMapError] = createSignal(false);

	const bounds = createMemo((): LatLngBounds | undefined => {
		const facilities = props.facilities as Array<DocumentType<typeof MapFragment>>;
		if (!facilities || !Array.isArray(facilities) || facilities.length === 0) {
			return undefined;
		}

		return facilities.reduce(
			(memo, facility) => {
				return [
					[Math.min(memo[0][0], facility.longitude!), Math.min(memo[0][1], facility.latitude!)],
					[Math.max(memo[1][0], facility.longitude!), Math.max(memo[1][1], facility.latitude!)],
				];
			},
			[
				[facilities[0]!.longitude!, facilities[0]!.latitude!],
				[facilities[0]!.longitude!, facilities[0]!.latitude!],
			] as LatLngBounds,
		);
	});

	createRenderEffect(() => {
		const container = ref();
		if (!container || map()) {
			return;
		}
		let createdMap: Map;
		try {
			createdMap = new mapbox.Map({
				projection: 'mercator',
				container,
				zoom: 10,
			});
		} catch (e) {
			captureException(e);
			setMapError(true);
			return;
		}

		setMap(createdMap);
		createdMap.resize();
		createdMap.on('load', () => createdMap.resize());
		createdMap.on('dragend', () => setShowSearchButton(true));

		props.ref({
			setFocus: (input?: FragmentType<typeof MapFragment> | FacilityMapInfoFragment) => {
				for (const el of document.querySelectorAll('[data-focus]')) {
					delete (el as HTMLDivElement).dataset.focus;
				}

				const facility = input as DocumentType<typeof MapFragment>;
				if (!facility) {
					return;
				}
				const marker = markers[facility.slug];
				if (!marker) {
					return;
				}

				(marker.getElement() as HTMLDivElement).dataset.focus = 'focus';
				for (const marker of Object.values(markers)) {
					marker.getPopup()?.remove();
				}
			},
		});
	});

	createEffect(() => {
		if (props.longitude && props.latitude) {
			map()?.resize();
			map()?.setCenter({ lng: props.longitude, lat: props.latitude });
			setShowSearchButton(false);
		}
	});

	createEffect(() => {
		const facilities = props.facilities as Array<DocumentType<typeof MapFragment>>;
		const theMap = map();
		if (!facilities || !Array.isArray(facilities) || !theMap) {
			return;
		}

		for (const [slug, marker] of Object.entries(markers)) {
			marker.remove();
			delete markers[slug];
		}

		if (!facilities.length) {
			return;
		}

		// Result order biases daily-fee first. Reversing ensures their markers are layered at the top on the map
		for (const facility of [...facilities].reverse()) {
			if (!facility.latitude || !facility.longitude) {
				return;
			}
			const el = markerRef()!.cloneNode() as HTMLElement;
			el.classList.add(mapClass[facility.type]);
			const parent = document.createElement('div');
			render(() => <MapPopover facility={facility} />, parent);
			const marker = new mapbox.Marker(el, { anchor: 'bottom' })
				.setLngLat([facility.longitude!, facility.latitude!])
				.setPopup(
					new mapbox.Popup({
						offset: 80,
						anchor: 'bottom',
						maxWidth: 'none',
						closeButton: false,
						closeOnMove: true,
						closeOnClick: true,
					}).setDOMContent(parent),
				)
				.addTo(theMap);
			const markerEl = marker.getElement();
			markerEl.addEventListener('click', (e) => {
				e.preventDefault();
				e.stopPropagation();
				props.onSelectFacility(facility);
			});
			markerEl.addEventListener('mouseenter', () => {
				marker.togglePopup();
			});
			markerEl.addEventListener('mouseleave', () => {
				marker.togglePopup();
			});
			markers[facility.slug] = marker;
		}
	});

	createRenderEffect(() => {
		const latLngBounds = bounds();
		const theMap = map();
		if (latLngBounds && theMap) {
			theMap.resize();
			theMap.fitBounds(latLngBounds, { padding: 32, animate: false });
		}
	});

	return (
		<div class="relative z-0 h-full">
			<div class="hidden">
				<MapMarker ref={setMarkerRef} />
			</div>
			<div ref={setRef} class="h-full">
				<Show when={mapError()}>
					<div class="grid h-full place-content-center bg-white">
						<p class="text-base">Failed to load map. Please try again.</p>
					</div>
				</Show>
			</div>
			<Show when={!mapError()}>
				<Show when={showSearchButton() && props.onSearchBounds}>
					<div class="pointer-events-none absolute inset-x-0 top-4 z-50 flex justify-center">
						<Button
							appearance="tertiary"
							size="sm"
							class="pointer-events-auto size-fit grow-0"
							onClick={() => {
								const bounds = map()?.getBounds();
								if (bounds) {
									const radius = Math.ceil(calcDist(bounds._ne, bounds._sw) / 2);
									trackEvent('mapSearch');
									props.onSearchBounds!(bounds.getCenter(), isNaN(radius) ? 3958.7559152 : radius);
								}
							}}
						>
							Search this area
						</Button>
					</div>
				</Show>
				<div class="absolute left-4 top-4 z-50 flex flex-col gap-2">
					<Button
						appearance="tertiary"
						size="sm"
						onClick={() => {
							trackEvent('mapZoomChange', { zoomMode: 'zoom in' });
							map()?.zoomIn();
						}}
					>
						<IconMagnifyingGlassPlus />
						<span class="sr-only">Zoom in</span>
					</Button>
					<Button
						appearance="tertiary"
						size="sm"
						onClick={() => {
							trackEvent('mapZoomChange', { zoomMode: 'zoom out' });
							map()?.zoomOut();
						}}
					>
						<IconMagnifyingGlassMinus />
						<span class="sr-only">Zoom out</span>
					</Button>
				</div>
				<Show when={props.onExpand}>
					{(onExpand) => (
						<Show
							when={!mapExpanded()}
							fallback={
								<div class="absolute inset-x-0 bottom-4 flex justify-center lg:bottom-auto lg:left-auto lg:right-4 lg:top-4">
									<Button
										appearance="tertiary"
										size="sm"
										class="size-fit grow-0"
										onClick={() => {
											onExpand()(false);
											trackEvent('mapDisplayChange', { mapMode: 'collapse' });
											setMapExpanded(false);
											map()?.resize();
										}}
									>
										<IconMenuAlt03 class="lg:hidden" />
										<IconShrink class="hidden lg:inline-flex" />
										<span class="lg:hidden">List View</span>
										<span class="hidden lg:inline-flex">Collapse</span>
									</Button>
								</div>
							}
						>
							<div class="absolute right-4 top-4 hidden lg:block">
								<Button
									appearance="tertiary"
									size="sm"
									onClick={() => {
										onExpand()(true);
										trackEvent('mapDisplayChange', { mapMode: 'expand' });
										setMapExpanded(true);
										map()?.resize();
										window.scrollTo({ top: 0 });
									}}
								>
									<IconExpand />
									<span class="sr-only">Expand map</span>
								</Button>
							</div>
							<div class="absolute inset-0 z-50 grid place-content-center bg-black/30 lg:hidden">
								<Button
									appearance="tertiary-current"
									class="bg-white hover:bg-white active:bg-white"
									size="sm"
									onClick={() => {
										onExpand()(true);
										setMapExpanded(true);
										map()?.resize();
										window.scrollTo({ top: 0, behavior: 'smooth' });
									}}
								>
									<IconMap />
									View Map
								</Button>
							</div>
						</Show>
					)}
				</Show>
			</Show>
		</div>
	);
}

export { MapContainer as Map, type Handler as MapHandler };

const MapFragment = gql(`fragment FacilityMapInfo on Facility {
	slug
	name
	type
	longitude
	latitude
	metadata {
		hero { url }
		address { city, state }
	}
}`);

function calcDist(...points: [{ lat: number; lng: number }, { lat: number; lng: number }]) {
	const earthRadius = 6371; // km
	const dLat = toRad(points[1].lat - points[0].lat);
	const dLon = toRad(points[1].lng - points[0].lng);

	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(points[0].lat) * Math.cos(points[1].lat);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	const d = earthRadius * c;
	return d * 0.6213712; // km to miles
}

// Converts numeric degrees to radians
function toRad(value: number) {
	return (value * Math.PI) / 180;
}

function MapMarker(props: { ref: (el: HTMLElement) => void }) {
	return (
		<div
			ref={props.ref}
			class="h-14 w-12 cursor-pointer bg-bottom bg-no-repeat hover:z-50 hover:h-16 hover:w-14 active:brightness-150 data-[focus]:z-50 data-[focus]:h-20 data-[focus]:w-16 data-[focus]:brightness-150"
		/>
	);
}

const mapClass: Record<FacilityType, string> = {
	[FacilityType.DailyFeeResort]:
		'bg-[url(https://images.ctfassets.net/rdsy7xf5c8dt/5Fu1dihQ93ru3fE2J5tfbx/7913ba6c61303d0ec32b856194a4fb6d/map-pin-daily-fee.svg)]',
	[FacilityType.Hospitality]:
		'bg-[url(https://images.ctfassets.net/rdsy7xf5c8dt/6zpNVGNeQgsSfTasQZAyMd/7fc4fa4457336e49c97d17a7abd05141/map-pin-hospitality.svg)]',
	[FacilityType.SemiPrivate]:
		'bg-[url(https://images.ctfassets.net/rdsy7xf5c8dt/3A1AZDXTM2xEw5QXATBH6R/4b828b2f4c8a1be2fb8eb0d2d6e69ee6/map-pin-semi-private.svg)]',
	[FacilityType.Private]:
		'bg-[url(https://images.ctfassets.net/rdsy7xf5c8dt/2CDRAiFocxDRsMEgfGkfWw/9c06a99f44e5eb688849573f2b81bdb2/map-pin-private.svg)]',
	[FacilityType.Residential]:
		'bg-[url(https://images.ctfassets.net/rdsy7xf5c8dt/1P2ah81KwL1B1atdeaKDsR/8aca7e6085fde7d0e6eb9df138b5bc35/map-pin-residential.svg)]',
};

function MapPopover(props: { facility: FacilityMapInfoFragment }) {
	return (
		<article class="flex items-center gap-3 lg:gap-4">
			<Picture
				src={props.facility.metadata?.hero?.url}
				alt=""
				width={200}
				height={200}
				sizes="8rem"
				class="size-24 shrink-0 rounded"
				crop="entropy"
			/>
			<div class="flex min-w-40 max-w-64 flex-col justify-center">
				<Heading as="h1" size="h5" class="truncate">
					{props.facility.name}
				</Heading>
				<p class="order-first text-xs uppercase tracking-widest text-neutral-800">
					{courseTypeString[props.facility.type]}
				</p>
				<p class="text-sm text-neutral-800">{Object.values(props.facility.metadata?.address ?? []).join(', ')}</p>
			</div>
		</article>
	);
}
